export const categoryList = [
    '城乡规划',
    '产业规划',
    '专项规划',
    '生态环保',
    '城乡水务',
    '景观工程',
    '水环境综合整治',
    '城市更新',
    '乡村振兴',
    '建筑工程',
    '燃气热力',
    '交通工程',
    '地下空间',
    '工程造价',
    '数智产业',
    'EPC'

]