var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('Pane'),_c('div',{staticClass:"container"},[_c('a-form',{attrs:{"form":_vm.form,"label-col":{ span: 8 },"wrapper-col":{ span: 12 },"colon":false},on:{"submit":_vm.handleSubmit}},[_c('a-row',[_c('a-col',{attrs:{"lg":12,"md":12,"sm":24}},[_c('a-form-item',{attrs:{"label":"标题"}},[_c('a-input',{directives:[{name:"decorator",rawName:"v-decorator",value:([
                'title',
                { rules: [{ required: true, message: '请输入！' }] },
              ]),expression:"[\n                'title',\n                { rules: [{ required: true, message: '请输入！' }] },\n              ]"}]})],1)],1),_c('a-col',{attrs:{"lg":12,"md":12,"sm":24}},[_c('a-form-item',{attrs:{"label":"分类"}},[_c('a-select',{directives:[{name:"decorator",rawName:"v-decorator",value:([
                'category',
                { rules: [{ required: true, message: '请选择！' }] },
              ]),expression:"[\n                'category',\n                { rules: [{ required: true, message: '请选择！' }] },\n              ]"}],staticStyle:{"width":"100%"},attrs:{"mode":"multiple"}},_vm._l((_vm.categoryList),function(item){return _c('a-select-option',{key:item,attrs:{"value":item}},[_vm._v(" "+_vm._s(item)+" ")])}),1)],1)],1),_c('a-col',{attrs:{"lg":12,"md":12,"sm":24}},[_c('a-form-item',{attrs:{"label":"地点"}},[_c('a-input',{directives:[{name:"decorator",rawName:"v-decorator",value:([
                'address',
                { rules: [{ required: true, message: '请输入！' }] },
              ]),expression:"[\n                'address',\n                { rules: [{ required: true, message: '请输入！' }] },\n              ]"}]})],1)],1),_c('a-col',{attrs:{"lg":12,"md":12,"sm":24}},[_c('a-form-item',{attrs:{"label":"首页是否显示"}},[_c('a-radio-group',{directives:[{name:"decorator",rawName:"v-decorator",value:([
                'stared',
                { rules: [{ required: true, message: '请选择！' }] },
              ]),expression:"[\n                'stared',\n                { rules: [{ required: true, message: '请选择！' }] },\n              ]"}]},[_c('a-radio',{attrs:{"value":0}},[_vm._v(" 否 ")]),_c('a-radio',{attrs:{"value":1}},[_vm._v(" 是 ")])],1)],1)],1),_c('a-col',{attrs:{"lg":24,"md":24,"sm":24}},[_c('a-form-item',{attrs:{"label":"简介","label-col":{ span: 4 },"wrapper-col":{ span: 18 }}},[_c('a-textarea',{directives:[{name:"decorator",rawName:"v-decorator",value:([
                'content',
                { rules: [{ required: true, message: '请输入！' }] },
              ]),expression:"[\n                'content',\n                { rules: [{ required: true, message: '请输入！' }] },\n              ]"}],attrs:{"auto-size":{ minRows: 3 }}})],1)],1),_c('a-col',{attrs:{"lg":24,"md":24,"sm":24}},[_c('a-form-item',{attrs:{"label":"规模","label-col":{ span: 4 },"wrapper-col":{ span: 18 }}},[_c('a-textarea',{directives:[{name:"decorator",rawName:"v-decorator",value:([
                'scale',
                { rules: [{ required: true, message: '请输入！' }] },
              ]),expression:"[\n                'scale',\n                { rules: [{ required: true, message: '请输入！' }] },\n              ]"}],attrs:{"auto-size":{ minRows: 3 }}})],1)],1),_c('a-col',{attrs:{"lg":24,"md":24,"sm":24}},[_c('a-form-item',{attrs:{"label":"获奖情况","label-col":{ span: 4 },"wrapper-col":{ span: 18 }}},[_c('a-textarea',{directives:[{name:"decorator",rawName:"v-decorator",value:(['award']),expression:"['award']"}],attrs:{"auto-size":{ minRows: 3 }}})],1)],1),_c('a-col',{attrs:{"lg":24,"md":24,"sm":24}},[_c('a-form-item',{attrs:{"label-col":{ span: 4 },"wrapper-col":{ span: 18 }}},[_c('span',{staticClass:"required",attrs:{"slot":"label"},slot:"label"},[_vm._v("图片")]),_c('FileUpload',{on:{"uploaded":_vm.uploaded}},[_c('a-button',{attrs:{"type":"primary","icon":"upload"}},[_vm._v(" 上传 ")]),_c('span',{staticStyle:{"color":"#999","padding-left":"8px"}},[_vm._v("图片大小建议在5MB以下")])],1),_c('div',{staticClass:"link-list"},_vm._l((_vm.fileList),function(item){return _c('div',{key:item,staticClass:"between link"},[_c('a',{attrs:{"target":"_blank","download":"","href":item}},[_vm._v(_vm._s(item))]),_c('div',{staticClass:"control",on:{"click":function($event){return _vm.deleteFile(item)}}},[_c('a-icon',{attrs:{"type":"delete"}})],1)])}),0)],1)],1)],1),_c('div',{staticClass:"center"},[_c('a-space',[_c('a-button',{attrs:{"type":"primary","html-type":"submit","loading":_vm.saveLoading}},[_vm._v("保存")]),_c('a-button',{on:{"click":function($event){return _vm.$close(_vm.$route.path)}}},[_vm._v("关闭")])],1)],1)],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }