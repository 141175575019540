import request from '../request'

export function fetchList(params) {
    return request({
        url: '/common-service/officialadmin/case/list',
        params
    })
}

export function add(data) {
    return request({
        url: '/common-service/officialadmin/case/add',
        method: 'POST',
        data
    })
}

export function fetchDetail(params) {
    return request({
        url: `/common-service/officialadmin/case/query/${params.id}`,
    })
}

export function edit(data) {
    return request({
        url: '/common-service/officialadmin/case/update',
        method: 'POST',
        data
    })
}

export function remove(data) {
    return request({
        url: '/common-service/officialadmin/case/del',
        method: 'POST',
        data
    })
}

export function approve(data) {
    return request({
        url: '/common-service/officialadmin/case/reivew/' + data.id,
        method: 'POST',
    })
}

